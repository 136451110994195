<template>
  <Section class="courses">
    <div class="courses__header">
      <Title class="courses__title">{{ $t("training_is") }} </Title>
      <Tooltip class="courses__tooltip">{{ $t("not_really") }} </Tooltip>
      <p class="courses__desc">
        {{ $t("djoon_is") }}
      </p>
    </div>

    <div class="courses__block-wrapper">
      <div class="courses__block-access">
        <span>{{ $t("full_time_access") }}</span>
        <img
          src="@/assets/images/zipper.png"
          width="23"
          height="23"
          alt="Zipper"
        />
      </div>

      <div class="courses__blocks">
        <h3 class="courses__block-title">{{ $t("manager_course.title") }}</h3>
        <div class="courses__blocks-wrapper">
          <div class="courses__block-main">
            <img
              class="courses__block-main-img"
              src="@/assets/images/courses/analyst.svg"
              width="98"
              height="68"
              alt="Analyst"
            />
            <div class="courses__block-main-right">
              <div class="courses__block-main-labels">
                <div class="courses__block-main-label">{{ $t("free_course") }}</div>
                <div class="courses__block-main-label">{{ $t("sales") }}</div>
              </div>
              <h4 class="courses__block-main-name">{{ $t("manager_course.name") }}</h4>
              <div class="courses__block-main-range"></div>
              <div class="courses__block-main-lesson-duration">
                {{ $t("manager_course.lessons") }}
              </div>
            </div>
          </div>
          <div class="courses__block-list">
            <div
              class="courses__block"
              v-for="(
                { name, image, lessonDuration, done }, index
              ) in lessonItems"
              :key="index"
            >
              <img
                class="courses__block-img"
                :src="image"
                :alt="name"
                width="60"
                height="60"
              />
              <div class="courses__block-right">
                <img
                  v-if="done"
                  class="courses__block-icon"
                  src="@/assets/images/check_mark.svg"
                  width="14"
                  height="14"
                  alt="Check mark"
                />

                <img
                  v-else
                  class="courses__block-icon"
                  src="@/assets/images/clock.svg"
                  width="14"
                  height="14"
                  alt="Check mark"
                />
                <div>
                  <h5 class="courses__block-name">{{ name }}</h5>
                  <div class="courses__block-lesson-duration">
                    {{ lessonDuration }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
</template>
<script>
import Section from "@/components/Section.vue";
import Title from "@/components/Title.vue";
import Tooltip from "@/components/Tooltip.vue";

export default {
  name: "CoursesSection",
  components: { Section, Title, Tooltip },
  data() {
    return {
      lessonItems: [
        {
          name: this.$t('manager_course.organizing'),
          lessonDuration: `${this.$t('lesson')} 1 / 20 мин`,
          image: require("@/assets/images/courses/1.png"),
          done: true,
        },
        {
          name: this.$t('manager_course.who_is'),
          lessonDuration: `${this.$t('lesson')} 2 / 20 мин`,
          image: require("@/assets/images/courses/2.png"),
          done: true,
        },
        {
          name: this.$t('manager_course.what_is_system_marketing'),
          lessonDuration: `${this.$t('lesson')} 3 / 20 мин`,
          image: require("@/assets/images/courses/3.png"),
          done: false,
        },
        {
          name: this.$t('manager_course.roadmap'),
          lessonDuration: `${this.$t('lesson')} 4 / 20 мин`,
          image: require("@/assets/images/courses/4.png"),
          done: false,
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.courses {
  &__header {
    text-align: center;
  }
  &__tooltip {
    margin-bottom: 8px;
  }
  &__title {
  }
  &__desc {
    font-weight: 500;
    font-size: 22px;
    color: #0b1f35;
    max-width: 662px;
    margin: 0px auto 18px;
  }
  &__block-wrapper {
    background: #ffffff;
    border-radius: 16px;
  }
  &__block-access {
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    background: #007bfc;
    border-radius: 0px 0px 16px 16px;
    padding: 10px 38px;
    max-width: 217px;
    margin: 0px auto 15px;
    display: flex;
    align-items: center;
    span {
      margin-right: 6px;
    }
  }

  &__blocks-wrapper {
    background: #ffffff;
    border-radius: 9px 9px 0px 0px;
  }
  &__blocks {
    max-width: 708px;
    margin: 0px auto;
    background: #f6f7f8;
    border-radius: 10px 10px 0px 0px;
    padding: 26px 35px 0px;
  }
  &__block-title {
    font-family: "Roboto";
    font-weight: 600;
    font-size: 17px;
    color: #0b1f35;
    margin-bottom: 10px;
  }
  &__block-main {
    display: flex;
    background: #0067ff;
    border-radius: 10px;
    padding: 12px 70px 21px 15px;
  }

  &__block-main-img {
    margin-right: 14px;
    background: #ffffff;
    border-radius: 10px;
    padding: 6px 6px 0px;
  }
  &__block-main-right {
    width: 100%;
  }
  &__block-main-labels {
    display: flex;
    align-items: center;
    padding-top: 6px;
    margin-bottom: 8px;
  }
  &__block-main-label {
    font-family: "Roboto";
    font-size: 4.23053px;
    color: #546272;
    padding: 1.81px 4.83px;
    background: #e9f4ff;
    border-radius: 10px;
    &:not(:last-child) {
      margin-right: 4px;
    }
  }
  &__block-main-name {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 13px;
    color: #ffffff;
    margin-bottom: 6px;
  }
  &__block-main-range {
    width: 100%;
    height: 3px;
    background: #dfdfdf;
    border-radius: 48px;
    margin-bottom: 6px;
    position: relative;
    &::before {
      content: "";
      width: 85px;
      height: 3px;
      top: 0;
      left: 0;
      position: absolute;
      background: #16c95d;
      border-radius: 48px;
    }
  }
  &__block-main-lesson-duration {
    font-family: "SF Pro Display";
    font-size: 8px;
    color: #ffffff;
  }
  &__block-list {
    padding: 14px 8px 0px 16px;
  }
  &__block {
    display: flex;
    background: #ffffff;
    border: 0.5px solid #dfdfdf;
    border-radius: 3px;
    overflow: hidden;
    &:not(:last-child) {
      margin-bottom: 9px;
    }
  }
  &__block-img {
    border-radius: 3px 0 0 3px;
    margin-right: 10px;
    // Баг - если у родительского блока заданно свойство border то картинка начинается не с самого начала левого края, и появляется лишнее пространство
    margin-left: -1px;
    object-fit: cover;
  }
  &__block-right {
    display: flex;
    padding-top: 16px;
  }
  &__block-icon {
    margin-right: 3px;
  }
  &__block-name {
    font-weight: 600;
    font-size: 10px;
    color: #0b1f35;
    margin-bottom: 4px;
  }
  &__block-lesson-duration {
    font-family: "SF Pro Display";
    font-size: 8px;
    color: #546272; 
  }
}
@media screen and (max-width: 468px) {
  .courses {
    &__title {
      font-size: 24px;
    }
    &__desc {
      font-size: 16px;
    }
  }
}
</style>
