<template>
  <Section class="hero">
    <div class="hero__inner">
      <div class="hero__content">
        <Title tag="h1" class="hero__title">
          {{ $t("djoon") }}
        </Title>

        <Tooltip> {{ $t("free") }}</Tooltip>
      </div>
      <img
        class="hero__image"
        width="492"
        height="343"
        src="@/assets/images/hero_img.webp"
        alt="Hero image"
      />
    </div>
  </Section>
</template>
<script>
import Tooltip from "@/components/Tooltip.vue";
import Section from "@/components/Section.vue";
import Title from "@/components/Title.vue";

export default {
  name: "HeroSection",
  components: { Tooltip, Section, Title },
};
</script>

<style scoped lang="scss">
.hero {
  padding-top: 5px;
  &__inner {
    display: flex;
    justify-content: space-between;
  }
  &__content {
    padding-top: 73px;
    max-width: 584px;
  }
  &__image {
    border-radius: 8px;
  }
  &__title {
    font-size: 44px;
  }
}
@media screen and (max-width: 670px) {
  .hero {
    &__title {
      font-size: 32px;
    }
    &__inner {
      flex-direction: column;
    }
    &__content {
      padding-top: 30px;
    }
    &__image{
        max-width: 100%;
        height: auto;
    }
  }
  .new-tooltip {
    font-size: 18px;
    padding: 8px 12px;
    margin-bottom: 20px;
  }
}
</style>
