<template>
  <Section class="courses-list">
    <div class="courses-list__content">
      <div class="courses-list__block">
        <h3 class="courses-list__block-title">{{ $t("sales") }}</h3>
        <img
          class="courses-list__block-img"
          src="@/assets/images/statistics.svg"
          width="257"
          height="210"
          alt="Statistics"
        />
        <router-link class="courses-list__block-link" to="/"
          >{{ $t("more") }}
        </router-link>
      </div>
      <div class="courses-list__block">
        <span class="courses-list__block-in-developing">{{ $t("in_production") }}</span>
        <h3 class="courses-list__block-title">{{ $t("SMM") }}</h3>
        <img
          class="courses-list__block-img"
          src="@/assets/images/user_engagement.svg"
          width="257"
          height="210"
          alt="Engagement"
        />
      </div>
    </div>
  </Section>
</template>
<script>
import Section from "@/components/Section.vue";

export default {
  name: "CoursesListSection",
  components: { Section },
};
</script>

<style scoped lang="scss">
.courses-list {
  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 33px;
  }
  &__block {
    position: relative;
    text-align: center;
    padding: 22px;
    border-radius: 16px;
    > * {
      &:not(:last-child) {
        margin-bottom: 39px;
      }
    }
    &:first-child {
      background: #007bfc;
      box-shadow: 0px 4px 30px rgba(0, 123, 252, 0.31);
    }
    &:last-child {
      background: #ffffff;
    }
  }
  &__block-title {
    font-weight: 600;
    font-size: 26px;
    &:first-child {
      color: #ffffff;
    }
    &:last-child {
      color: #0b1f35;
    }
  }
  &__block-img {
    display: block;
    margin: 0px auto;
  }
  &__block-link {
    font-weight: 400;
    font-size: 16px;
    color: #0b1f35;
    background: #ffffff;
    border: 1px solid #b6bcc3;
    border-radius: 4px;
    padding: 8px 20px;
    display: inline-block;
    &:hover {
      text-decoration: none;
    }
  }
  &__block-in-developing {
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    padding: 10px;
    background: #f4b922;
    border-radius: 16px 0px;
    border-radius: 16px 0px 16px 0px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }
}
@media screen and (max-width: 468px) {
  .courses-list {
    &__content {
      grid-template-columns: 1fr;
    }
  }
}
</style>
