<template>
  <div>
    <HeroSection />
    <CoursesSection />
    <CoursesListSection />
    <AdvantagesSection />
  </div>
</template>
<script>
import HeroSection from "./sections/HeroSection.vue";
import CoursesSection from "./sections/CoursesSection.vue";
import CoursesListSection from "./sections/CoursesListSection.vue";
import AdvantagesSection from "./sections/AdvantagesSection.vue";

export default {
  name: "NewHome",
  components: {
    HeroSection,
    CoursesSection,
    CoursesListSection,
    AdvantagesSection,
  },
};
</script>
